<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import ResponsivePowerBiReportWrapper from '@cox2m/city-services-ui-components/src/components/ResponsivePowerBiReportWrapper.svelte';
  import {menuOpts, user} from '../stores';

  export let params;

  let pageName;
  let reportPages;

  let modalContext;


  const setPage = async (pageName) => {
    if(pageName && reportPages){
      const page = reportPages.find(page => page.displayName === pageName);
      page && (await page.setActive());
    }
  }

  const handleOnLoad = async (e) => {
    let report;

    if(e && e.detail.report){
      report = e.detail.report;
      reportPages = await report.getPages();
      await setPage(pageName);
    }
  }

  $: pageName = decodeURI(params.page) || null;
  $: setPage(pageName);
</script>

<Modal bind:this={modalContext}>
  <MainScreen title="Analytics" menuOpt={$menuOpts} user={$user}>
    <div slot="main-dashboard" class="w-100 h-100">
      <ResponsivePowerBiReportWrapper
        reportParams = {{
         desktopReport: {
           reportId: "cdf65488-5183-4aac-8a0b-360f25b7b07a",
           workspaceId: "2a9d04e1-3229-4343-87ce-8519e3d93747",
           datasetIds: "e37e9836-32d9-457a-8e7a-bbebf3199437",
           height: '90vh'
         },
         mobileReport: {
           reportId: "cdf65488-5183-4aac-8a0b-360f25b7b07a",
           workspaceId: "2a9d04e1-3229-4343-87ce-8519e3d93747",
           datasetIds: "e37e9836-32d9-457a-8e7a-bbebf3199437",
           height: '90vh'
         },
         modalReport: {
           reportId: "cdf65488-5183-4aac-8a0b-360f25b7b07a",
           workspaceId: "2a9d04e1-3229-4343-87ce-8519e3d93747",
           datasetIds: "e37e9836-32d9-457a-8e7a-bbebf3199437",
           height: '600px',
           showModal: true,
         }
       }}
        on:load={handleOnLoad}
      />
    </div>
  </MainScreen>

</Modal>
