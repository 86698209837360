<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import OccupiedIcon from './components/parking-spaces/OccupiedIcon.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';

  import { getCookieByName } from '@cox2m/city-services-ui-components/src/funcs';
  import {getParkingSpacesOccupied} from '../actions';
  import {menuOpts, selectedPark, user} from '../stores';
  import {onMount} from 'svelte';

  const token = getCookieByName(`${'ENV'}_token`);

  let parkingFetchError = false;
  let loading = true;


  const columns = [
    {
      label: 'Lot number',
      key: 'lot_number',
      type: 'number'
    },
    {
      label: 'occupied',
      key: 'occupied_string',
      type: 'string'
    },
    {
      label: '',
      key: 'occupied',
      Component: OccupiedIcon,
    },
  ];

  let rows = [];

  let isSiteLoading;
  let isSiteOnError;

  const initializeParkingSpaces = async () => {
    const parkingSpacesResponse = await getParkingSpacesOccupied(token, $selectedPark && $selectedPark.name ? {'siteName': $selectedPark.name} : {});

    if(parkingSpacesResponse.fulfilled){
      rows = [];
      parkingSpacesResponse.spaces.forEach(item => {
          rows = [...rows, {lot_number: item.spaceNumber, occupied_string: item.occupied ? 'yes' : 'no' ,occupied: item.occupied}];
        });
      loading = false;
    }else{
      parkingFetchError = true;
      loading = false;
    }
  }


  onMount(async () => {
    await initializeParkingSpaces();
  });

  $: initializeParkingSpaces($selectedPark)
</script>

<style>
  .map-image{
    max-width: 650px;
    height: auto;
    border-radius: 1rem;
  }
  .dashboard-component-container{
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
  img{
    border-radius: 16px;
  }
</style>

<MainScreen title="Parking Spaces" menuOpt={$menuOpts} user={$user}>
  <div slot="main-dashboard" >
    <div class="w-100">
      <SiteIndicator title="Parking Spaces" bind:isLoading={isSiteLoading} bind:isOnError={isSiteOnError}/>
    </div>
    <div class="dashboard-component-container">

      {#if loading || isSiteLoading}
        <div
          class="loading-container w-100 h-100 d-flex justify-content-center
          align-items-center">
          <LoadingSpinner />
        </div>
      {:else if parkingFetchError || isSiteOnError}
        <div
          class="loading-container w-100 h-100 d-flex justify-content-center
          align-items-center">
          <h2 class="text-center">We are sorry we could not fetch de parking spaces information, please reload the page or try again later</h2>
        </div>
      {:else}
      <div>
        <div class="d-flex justify-content-center align-items-center mb-5">
          <img src="images/parking-map.png" class="w-100 map-image" alt="parking-map">
        </div>
        <Table
              id="parking-occupancy-table"
              {columns}
              {rows}
              pagination={true}
              itemsPerPage={50} />
      </div>
      {/if}
    </div>
  </div>
</MainScreen>
