//this is like the enumerator from pbi models
const models = {
  FilterType: {
    Advanced: 0,
    Basic: 1,
    Unknown: 2,
    IncludeExclude: 3,
    RelativeDate: 4,
    TopN: 5,
    Tuple: 6,
    RelativeTime: 7,
    Identity: 8,
    Hierarchy: 9
  }
};

export const getMarkerConfig = (color = '#2E6CE4', iconUrl = null) => {
  if (!iconUrl) {
    return {
      type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
      path:
        'M256 0c-88.366 0-160 71.634-160 160 0 160 160 352 160 352s160-192 160-352c0-88.366-71.635-160-160-160zM256 256c-53.020 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z',
      color: color, //this color should be coming from the ones in the design system variables
      outline: {
        color: [0, 0, 0, 0.7],
        width: 0.5
      },
      size: 25
    };
  } else if (iconUrl) {
    return {
      type: 'picture-marker', // autocasts as new pictureMarkerSymbol()
      url: iconUrl,
      width: '47px',
      height: '47px',
      yoffset: 23
    };
  }
};

export const getClusterConfig = () => {
  return {
    type: 'cluster',
    clusterRadius: '100px',
    // {cluster_count} is an aggregate field containing
    // the number of features comprised by the cluster
    popupEnabled: false,
    clusterMinSize: '16px',
    clusterMaxSize: '32px',
    labelingInfo: [
      {
        deconflictionStrategy: 'none',
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
        },
        symbol: {
          type: 'text',
          color: '#2E6CE4', //this color should be coming from the ones in the design system variables
          font: {
            weight: 'bold',
            family: 'Noto Sans',
            size: '12px'
          }
        },
        labelPlacement: 'center-center'
      }
    ]
  };
};

/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Useful for resolving race conditions.
 *
 * @param selector
 * @returns {Promise}
 */
export function waitForElementExistance(selector) {
  return new Promise((resolve, reject) => {
    let el = document.querySelector(selector);

    if (el) {
      resolve(el);
      return;
    }

    new MutationObserver((mutationRecords, observer) => {
      // Query for elements matching the specified selector
      Array.from(document.querySelectorAll(selector)).forEach(element => {
        resolve(element);
        //Once we have resolved we don't need the observer anymore.
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  });
}

export const getEventImageAsBase64 = async ({eventId, axios, token, showDetectionScore}) => {
  const response = await axios.get(`API_HOST/event/image/${eventId}?showDetectionScore=${showDetectionScore}`, {
    headers: {'X-Token': token},
    responseType: 'arraybuffer'
  });

  if (response.status === 200) {
    return response.data;
  }
  return '';
};

export const getEventVideoAsBase64 = async ({eventId, axios, token}) => {
  const response = await axios.get(`API_HOST/event/${eventId}`, {
    headers: {'X-Token': token},
    responseType: 'arraybuffer'
  });

  if (response.status === 200) {
    return response.data;
  }
  return '';
};

export const clearSockets = WebSocket => {
  if (WebSocket) {
    WebSocket.onclose = () => {};
    WebSocket.close();
  }
};

/**
 * Creates a power BI basic filter
 *
 * @param {string} table
 * @param {string} column
 * @param {string} operator => "In" | "NotIn" | "All"
 * @param {Array} values
 * @returns {Promise}
 */

export const buildPbiBasicFilter = ({
  table = 'cox2m-analytics.smart_security.baker_park_event',
  column = 'camera-site',
  operator = 'In',
  values
}) => {

  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table,
      column
    },
    operator,
    values,
    filterType: models.FilterType.Basic,
    requireSingleSelection: true
  };
};

/**
 * Get rtsp credentials
 * @param url
 */
export const getRtspCredentials = (url) => {
  const rtspRegex = /rtsp:\/\/([a-z_A-Z0-9]+):([a-z_A-Z0-9%]+)+/gi;
  const [_, username, password] = rtspRegex.exec(url);
  return { username, password: decodeURIComponent(password) };
};

/**
 * Get RTSP path
 * @param url
 */
export const getRtspPath = (url) => {
  const lastIndex = url.lastIndexOf("/");
  if (lastIndex > -1) {
    return url.substring(lastIndex + 1);
  }
  return '';
};
