<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import axios from 'axios';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {Buffer} from 'buffer';

  export let props = {};

  let loading = false;
  let error = false;

  const TOKEN = getCookieByName(`${'ENV'}_token`);
  let licensePlateImage = null;

  const hexToBase64 = data => {
    return Buffer.from(data).toString('base64');
  };

  const getLicensePlateImageAsBase64 = async vehicleId => {
    try {
      loading = true;
      const response = await axios.get(
        `API_HOST/vehicle/license-plate/${vehicleId}`,
        {
          headers: {'X-Token': TOKEN},
          responseType: 'arraybuffer'
        }
      );
      if (response.status === 200) {
        return hexToBase64(response.data);
      }
    } catch (e) {
      error = true;
      return '';
    }finally {
      loading = false;
    }
  };

  const setLicensePlate = async () => {
    error = false;
    licensePlateImage = await getLicensePlateImageAsBase64(props.id);
  };

  $: setLicensePlate(props.id);
</script>

<style>
  img {
    max-width: var(--cox2m-spacing-30-units);
  }
</style>

{#if loading && !error}
  <LoadingSpinner />
{:else}
  <img
    src={`data:image/jpeg;base64,${licensePlateImage}`}
    alt={props.licensePlate.value} />
{/if}
