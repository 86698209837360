"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient["default"];
  }
});
Object.defineProperty(exports, "BoxDto", {
  enumerable: true,
  get: function get() {
    return _BoxDto["default"];
  }
});
Object.defineProperty(exports, "CameraApi", {
  enumerable: true,
  get: function get() {
    return _CameraApi["default"];
  }
});
Object.defineProperty(exports, "CameraDto", {
  enumerable: true,
  get: function get() {
    return _CameraDto["default"];
  }
});
Object.defineProperty(exports, "DateDto", {
  enumerable: true,
  get: function get() {
    return _DateDto["default"];
  }
});
Object.defineProperty(exports, "DefaultApi", {
  enumerable: true,
  get: function get() {
    return _DefaultApi["default"];
  }
});
Object.defineProperty(exports, "ErrorResponse", {
  enumerable: true,
  get: function get() {
    return _ErrorResponse["default"];
  }
});
Object.defineProperty(exports, "EventApi", {
  enumerable: true,
  get: function get() {
    return _EventApi["default"];
  }
});
Object.defineProperty(exports, "GalleryAssetTypeDto", {
  enumerable: true,
  get: function get() {
    return _GalleryAssetTypeDto["default"];
  }
});
Object.defineProperty(exports, "LicensePlateDto", {
  enumerable: true,
  get: function get() {
    return _LicensePlateDto["default"];
  }
});
Object.defineProperty(exports, "NotificationRequestDto", {
  enumerable: true,
  get: function get() {
    return _NotificationRequestDto["default"];
  }
});
Object.defineProperty(exports, "NotificationsApi", {
  enumerable: true,
  get: function get() {
    return _NotificationsApi["default"];
  }
});
Object.defineProperty(exports, "OccupiedParkingSpaceDto", {
  enumerable: true,
  get: function get() {
    return _OccupiedParkingSpaceDto["default"];
  }
});
Object.defineProperty(exports, "ParkOperationSettingsDto", {
  enumerable: true,
  get: function get() {
    return _ParkOperationSettingsDto["default"];
  }
});
Object.defineProperty(exports, "ParkingApi", {
  enumerable: true,
  get: function get() {
    return _ParkingApi["default"];
  }
});
Object.defineProperty(exports, "ParkingSpaceDto", {
  enumerable: true,
  get: function get() {
    return _ParkingSpaceDto["default"];
  }
});
Object.defineProperty(exports, "ParksPeopleDto", {
  enumerable: true,
  get: function get() {
    return _ParksPeopleDto["default"];
  }
});
Object.defineProperty(exports, "ParksViolationPeopleParkingDto", {
  enumerable: true,
  get: function get() {
    return _ParksViolationPeopleParkingDto["default"];
  }
});
Object.defineProperty(exports, "PeopleApi", {
  enumerable: true,
  get: function get() {
    return _PeopleApi["default"];
  }
});
Object.defineProperty(exports, "PeopleDto", {
  enumerable: true,
  get: function get() {
    return _PeopleDto["default"];
  }
});
Object.defineProperty(exports, "SecurityEventDto", {
  enumerable: true,
  get: function get() {
    return _SecurityEventDto["default"];
  }
});
Object.defineProperty(exports, "SiteApi", {
  enumerable: true,
  get: function get() {
    return _SiteApi["default"];
  }
});
Object.defineProperty(exports, "SiteDto", {
  enumerable: true,
  get: function get() {
    return _SiteDto["default"];
  }
});
Object.defineProperty(exports, "VehicleApi", {
  enumerable: true,
  get: function get() {
    return _VehicleApi["default"];
  }
});
Object.defineProperty(exports, "VehicleDto", {
  enumerable: true,
  get: function get() {
    return _VehicleDto["default"];
  }
});
Object.defineProperty(exports, "ViolationsCountDto", {
  enumerable: true,
  get: function get() {
    return _ViolationsCountDto["default"];
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _BoxDto = _interopRequireDefault(require("./model/BoxDto"));
var _CameraDto = _interopRequireDefault(require("./model/CameraDto"));
var _DateDto = _interopRequireDefault(require("./model/DateDto"));
var _ErrorResponse = _interopRequireDefault(require("./model/ErrorResponse"));
var _GalleryAssetTypeDto = _interopRequireDefault(require("./model/GalleryAssetTypeDto"));
var _LicensePlateDto = _interopRequireDefault(require("./model/LicensePlateDto"));
var _NotificationRequestDto = _interopRequireDefault(require("./model/NotificationRequestDto"));
var _OccupiedParkingSpaceDto = _interopRequireDefault(require("./model/OccupiedParkingSpaceDto"));
var _ParkOperationSettingsDto = _interopRequireDefault(require("./model/ParkOperationSettingsDto"));
var _ParkingSpaceDto = _interopRequireDefault(require("./model/ParkingSpaceDto"));
var _ParksPeopleDto = _interopRequireDefault(require("./model/ParksPeopleDto"));
var _ParksViolationPeopleParkingDto = _interopRequireDefault(require("./model/ParksViolationPeopleParkingDto"));
var _PeopleDto = _interopRequireDefault(require("./model/PeopleDto"));
var _SecurityEventDto = _interopRequireDefault(require("./model/SecurityEventDto"));
var _SiteDto = _interopRequireDefault(require("./model/SiteDto"));
var _VehicleDto = _interopRequireDefault(require("./model/VehicleDto"));
var _ViolationsCountDto = _interopRequireDefault(require("./model/ViolationsCountDto"));
var _CameraApi = _interopRequireDefault(require("./api/CameraApi"));
var _DefaultApi = _interopRequireDefault(require("./api/DefaultApi"));
var _EventApi = _interopRequireDefault(require("./api/EventApi"));
var _NotificationsApi = _interopRequireDefault(require("./api/NotificationsApi"));
var _ParkingApi = _interopRequireDefault(require("./api/ParkingApi"));
var _PeopleApi = _interopRequireDefault(require("./api/PeopleApi"));
var _SiteApi = _interopRequireDefault(require("./api/SiteApi"));
var _VehicleApi = _interopRequireDefault(require("./api/VehicleApi"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }