<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import TimePicker from '@cox2m/city-services-ui-components/src/components/time/TimePicker.svelte';
  import ToggleSwitch from '@cox2m/city-services-ui-components/src/forms/ToggleSwitch.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';

  import {
    getCookieByName,
    setSuccessToast
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts, selectedPark, user} from '../stores';
  import {onMount} from 'svelte';
  import {
    getParkOperationSettings,
    putParkOperationSettings
  } from '../actions';

  let loading = true;
  let parkOperationSettingsDays = [];
  let isSiteLoading;
  let isSiteOnError;

  const token = getCookieByName(`${'ENV'}_token`);

  const saveParkOperationSettings = async () => {
    const sendResponse = await putParkOperationSettings(token, parkOperationSettingsDays.map(parkOperationSetting => ({
      ...parkOperationSetting,
      siteName: $selectedPark.name
    })));
    if (sendResponse.fulfilled) {
      setSuccessToast(window.dispatchEvent, 'Saved');
    }
  };

  onMount(async () => {
    const parkOperationSettingsResponse = await getParkOperationSettings(token, $selectedPark ?{siteName: $selectedPark.name} :{});
    if (parkOperationSettingsResponse.fulfilled) {
      parkOperationSettingsDays = parkOperationSettingsResponse.data
    }
    loading = false;
  });
</script>

<style>
  .park-hours-content-container {
    padding: var(--cox2m-spacing-7-units) var(--cox2m-spacing-9-units);
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    max-width: 900px;
    margin: 0 auto;
  }
  .day-schedule-picker-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--cox2m-spacing-12-units);
    row-gap: var(--cox2m-spacing-3-units);
  }
  .day-switch-container,
  .schedule-switch {
    display: flex;
    align-items: center;
  }
  .button-controllers{
    display: flex;
    justify-content: flex-end;
  }
  .day-label {
    width: var(--cox2m-spacing-30-units);
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-neutral-black);
  }
  .park-hours-title{
    font-size: var(--cox2m-fs-800);
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-neutral-black);
    margin-bottom: var(--cox2m-spacing-3-units);
  }
  .park-hours-info{
    color: var(--cox2m-clr-neutral-700);
    font-weight: var(--cox2m-fw-medium);
    margin-bottom: var(--cox2m-spacing-12-units);
  }
  .switch-status-label{
    font-weight: 300;
    margin-left: var(--cox2m-spacing-3-units);
  }
  @media only screen and (min-width: 1200px) {
    :global(.park-hours-content-container .pickers-container.visible) {
      right: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .day-schedule-picker-container {
      flex-direction: column;
      align-items: center;
    }
  }
</style>

<MainScreen title="Park Hours" menuOpt={$menuOpts} user={$user}>
  <div slot="main-dashboard" class="w-100 h-100">
    <div class="w-100">
      <SiteIndicator title="Park Hours" bind:isLoading={isSiteLoading} bind:isOnError={isSiteOnError}/>
    </div>

    {#if isSiteLoading}
    <div class="w-100 h-100">
      <LoadingSpinner />
    </div>
    {:else if !isSiteLoading && isSiteOnError}
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <h3 >
        we are sorry we cannot fetch the information, please refresh the page or try again later
      </h3>
    </div>
    {:else if !isSiteLoading && !isSiteOnError}
      <div class="park-hours-content-container cox2m-shadow-1-dp">
        <h2 class="park-hours-title">Set Standard Hours</h2>
        <p class="park-hours-info">Configure the standard hour of operation for this park.</p>
        {#each parkOperationSettingsDays as dayInfo, dayIndex}
          <div class="day-schedule-picker-container">
            <div class="day-switch-container">
              <span class="day-label">{dayInfo.dayOfWeek}</span>
              <div class="schedule-switch">
                <ToggleSwitch
                  id="{dayInfo.dayOfWeek}-hours-switch"
                  disabled={false}
                  bind:checked={parkOperationSettingsDays[dayIndex].isOpen} />
                <span class="switch-status-label">
                  {parkOperationSettingsDays[dayIndex].isOpen ? 'Open' : 'Closed'}
                </span>
              </div>
            </div>
            <div>
              <TimePicker
                id="{dayInfo.dayOfWeek}-hours-picker"
                on:dateSelectionChange={e => {
                  parkOperationSettingsDays[dayIndex].initialSchedule = e.detail.selectedStartTime.userFriendlyFormat;
                  parkOperationSettingsDays[dayIndex].finalSchedule = e.detail.selectedEndTime.userFriendlyFormat;
                }}
                initialStartTime={parkOperationSettingsDays[dayIndex].initialSchedule}
                initialEndTime={parkOperationSettingsDays[dayIndex].finalSchedule}
                timeRange />
            </div>
          </div>
        {/each}

        <div class="button-controllers">
          <!-- <Button
            customClass="font-weight-bold"
            text="Cancel"
            width="fit-content"
            height="var(--cox2m-spacing-10-units)"
            color="var(--cox2m-clr-brand-600)"
            borderColor="var(--cox2m-clr-brand-300)"
            backgroundColor="transparent"
            padding="0 var(--cox2m-spacing-4-units)"
            on:click={() => {
              alert(123);
            }} /> -->

          <Button
            customClass="font-weight-bold"
            text="Save Schedule"
            width="fit-content"
            height="var(--cox2m-spacing-10-units)"
            backgroundColor="var(--cox2m-clr-brand-600)"
            color="var(--cox2m-clr-neutral-white)"
            margin="0 0 0 var(--cox2m-spacing-7-units)"
            padding="0 var(--cox2m-spacing-4-units)"
            on:click={() => saveParkOperationSettings()} />
        </div>
      </div>
    {/if}
  </div>
</MainScreen>
