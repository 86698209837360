<script>
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import axios from 'axios';
  import LogRocket from 'logrocket';
  import routes from './routes';

  import Router, {querystring} from 'svelte-spa-router';
  import {
    getCookieByName,
    getUser,
    initializePendo,
    trackEvent
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {initializeIdleTimer} from '@cox2m/city-services-ui-components/src/idle-timer.js';
  import {getAnalytics, logEvent} from 'firebase/analytics';
  import {initializeApp} from 'firebase/app';
  import {onMount, setContext} from 'svelte';
  import {user, menuOpts, selectedPark} from './stores';

  import {
    staticMenuOptions,
    buildMenuWithDynamicItems
  } from './menuOptions/populateMenu';
  import {startLiveStreamSite} from "./actions";

  const TOKEN = getCookieByName(`${'ENV'}_token`);
  let analytics = null;

  onMount(async () => {
    const USER_DATA = (await getUser(TOKEN)) || '{}';
    user.logout();
    user.saveUser({
      ...USER_DATA,
      isCoxUser: USER_DATA.organizationId === 'O_000000'
    });

    // Initialize LogRocket
    const logRocketAppId = `${'LOGROCKET_APP_ID'}`;
    if (
      logRocketAppId &&
      USER_DATA &&
      USER_DATA.groupNames &&
      !USER_DATA.groupNames.includes('ADMIN')
    ) {
      LogRocket.init(logRocketAppId);
      const uid = TOKEN.substring(0, 8);
      LogRocket.identify(uid);

      setContext('logrocket', {
        getLogRocket: () => LogRocket
      });
    }

    const firebaseConfigStr = `${'FIREBASE_CONFIG'}`;
    if (firebaseConfigStr) {
      const firebaseConfig = JSON.parse(firebaseConfigStr);
      const app = initializeApp(firebaseConfig);
      // Initialize Analytics and get a reference to the service
      analytics = getAnalytics(app);
      setContext('analytics', {
        getAnalytics: () => analytics
      });
    }

    const account = {
      id: $user.personId,
      givenName: $user.givenName,
      familyName: $user.familyName,
      email: $user.email
    };
    initializePendo(account, account);
    window.addEventListener('networkErrorEvent', sendNetworkErrorAsEvent);
    await initializeLiveStreamForSite();
    await initializeIdleTimer(TOKEN);
  });

  /**
   * Initialize the live-streaming cameras for the current site
   * @returns {Promise<void>}
   */
  const initializeLiveStreamForSite = async () => {
    let site;
    if ($querystring.includes('site')) {
      const queryParams = new URLSearchParams($querystring);
      site = queryParams.get('site');
    } else {
      site = 'Nevada-LasVegas-Baker_Park';
    }

    const {fulfilled} = await startLiveStreamSite(axios, TOKEN, site);
    if (!fulfilled) {
      console.warn(`Cannot initialize live streaming cameras for the site: ${site}`);
    }
  };

  /**
   * Send a network error event as an analytics event
   * @param event
   */
  const sendNetworkErrorAsEvent = event => {
    trackEvent(event.detail.title, event.detail);
  };

  const createLatestUrlCookie = () => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 600000);
    document.cookie = `${'ENV'}_cox2mlatesturl=${
      window.location.href
    };expires=${futureDate.toUTCString()};domain=cox2m.com;`;
  };

  const routeLoaded = event => {
    window.dispatchEvent(new CustomEvent('clearSockets'));
    createLatestUrlCookie();
    if (analytics) {
      logEvent(analytics, 'screen_view', {
        firebase_screen: event.detail.location,
        firebase_screen_class: event.detail.name
      });
    }
  };

  const setMenuOptions = async () => {
    if ($user && $user.operations && TOKEN) {
      new Promise((resolve) => {
        resolve(() => {
          menuOpts.updateMenuValues(staticMenuOptions($user));
        })
      }).then(async () => {
        menuOpts.updateMenuValues(await buildMenuWithDynamicItems($user, TOKEN, $selectedPark));
      })
    }
  };

  $: setMenuOptions($user, $selectedPark);
</script>

<style>
  :global(body) {
    display: flex;
    background: linear-gradient(
      135deg,
      var(--cox2m-clr-primary-identity-light) 0%,
      var(--cox2m-clr-primary-identity-dark) 100%
    );
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    font-family: 'montserrat', 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
      Cantarell, 'Helvetica Neue', sans-serif;
  }

  :global(::-webkit-scrollbar) {
    width: 10px;
  }

  /* Track */
  :global(::-webkit-scrollbar-track) {
    background: var(--cox2m-clr-neutral-400);
    border-radius: 10px;
  }

  /* Handle */
  :global(::-webkit-scrollbar-thumb) {
    background: var(--cox2m-clr-neutral-600);
    border-radius: 10px;
  }

  /* Handle on hover */
  :global(::-webkit-scrollbar-thumb:hover) {
    background: var(--cox2m-clr-neutral-700);
  }
</style>

<svelte:head>
  <script>
    const pendoApiKey = `${'PENDO_API_KEY'}`;
    (function(apiKey) {
      (function(p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src =
          'https://cdn.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(pendoApiKey);
  </script>
</svelte:head>

<Modal>
  <Router {routes} on:routeLoaded={routeLoaded} />
</Modal>
