import {getPowerBiReportPages} from '@cox2m/city-services-ui-components/src/funcs';

const VIOLATIONS_ROUTE_REGEX = /\/violations\/.*/;

export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'parks-and-rec',
    name: 'Parks & Rec',
    icon: 'elements-plus',
    shouldShow: true,
    subItems: [
      {
        key: 'maintenance-dashboard',
        path: '/maintenance-dashboard',
        name: 'Overview',
        icon: 'dashboard',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-parkrec-dashboard')
      },
      {
        key: 'park-assets',
        path: '/park-assets',
        name: 'Park assets',
        icon: 'camera',
        shouldShow: true
      }
    ]
  },
  {
    key: 'public-safety',
    name: 'Public Safety',
    icon: 'focus-target', //should be changed for the new icon that luisa is going to send
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-enforcement-dashboard'),
    subItems: [
      {
        key: 'dashboard',
        path: '/dashboard',
        name: 'Overview',
        icon: 'dashboard',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-enforcement-dashboard')
      },
      {
        key: 'live-view',
        path: '/live-view',
        name: 'Live View',
        icon: 'video-camera',
        shouldShow: true
      },
      {
        key: 'violations',
        path: '/violations',
        name: 'Violations',
        icon: 'alert-circle',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-violations-using-filter'),
        alternateSelectionCondition: () =>
          VIOLATIONS_ROUTE_REGEX.test(window.location.hash.substring(1))
      }
    ]
  },

  {
    key: 'analytics',
    name: 'Analytics',
    icon: 'chart-column',
    shouldShow: true
  },

  {
    key: 'monitoring',
    name: 'Monitoring',
    icon: 'chart-line',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-events-using-filter'),
    subItems: [
      {
        key: 'events',
        path: '/monitoring/events',
        name: 'Events',
        icon: 'alert-circle',
        shouldShow: true
      },
      {
        key: 'parking-spaces',
        path: '/monitoring/parking-spaces',
        name: 'Parking Spaces',
        icon: 'grid',
        shouldShow: true
      },
      {
        key: 'status',
        path: '/monitoring/status',
        name: 'Status',
        icon: 'activity',
        shouldShow: true
      },
      {
        key: 'license-plates',
        path: '/monitoring/license-plates',
        name: 'License Plates',
        icon: 'list-unordered',
        shouldShow: true
      },
      {
        key: 'devices',
        path: '/devices',
        name: 'Devices',
        icon: 'devices',
        shouldShow: true
      }
    ]
  },
  {
    key: 'settings',
    name: 'Settings',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      ((user.operations.includes('get-park-operation-settings') &&
        user.operations.includes('put-park-operation-settings')) ||
        (user.operations.includes('get-enforcement-dashboard') &&
          user.operations.includes('get-notifications-metrics-by-app-id'))),
    subItems: [
      {
        key: 'park-hours',
        path: '/park-hours',
        name: 'Park Hours',
        icon: 'clock',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-park-operation-settings') &&
          user.operations.includes('put-park-operation-settings')
      },
      {
        key: 'alerts-notifications',
        path: '/alerts',
        name: 'Alerts+Notifications',
        icon: 'bell',
        shouldShow:
          user &&
          user.operations &&
          user.operations.includes('get-enforcement-dashboard') &&
          user.operations.includes('get-notifications-metrics-by-app-id')
      }
    ]
  },
  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];

export const buildMenuWithDynamicItems = async (user, TOKEN, selectedPark) => {
  let tempMenuOptions = staticMenuOptions(user);

  try {
    let analyticsEntry = tempMenuOptions.find(
      menuOpt => menuOpt.key === 'analytics'
    );

    if (
      user &&
      user.operations &&
      user.operations.includes('get-freemont-analytics') &&
      ['Freemont-Street'].includes(selectedPark.name)
    ) {
      analyticsEntry.subItems = [
        {
          key: `thoughtspot-analytics`,
          path: `/thoughtspot-analytics`,
          name: 'freemont st test report',
          icon: 'clipboard-text',
          shouldShow: true
        }
      ];
    } else {
      const response = await getPowerBiReportPages({
        workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747',
        reportId: 'cdf65488-5183-4aac-8a0b-360f25b7b07a',
        token: TOKEN
      });

      analyticsEntry.subItems = response.value.map(page => ({
        key: `report-page-${page.order}`,
        path: `/analytics/${encodeURI(page.displayName)}`,
        name: page.displayName,
        icon: 'clipboard-text',
        shouldShow: true
      }));
    }
  } catch (e) {
    console.error(e);
  }

  return tempMenuOptions;
};
