export const InviteStrategies = {
    ACTIVE_DIRECTORY: 'active_directory',
    REGULAR_EMAIL: 'email'
}
export const EMAIL_REGEX = /^.+@.+\..+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const PHONE_NUMBER_REGEX = /^\+[0-9]{1,15}$/;

export const HLS_CONFIG = {
  nudgeOffset: 0.5,
  progressive: true,
  enableWorker: true,
  lowLatencyMode: true,
  nudgeMaxRetry: 100,
  maxBufferLength: 3600,
  liveSyncDurationCount: 5,
  liveDurationInfinity: true,
  maxLiveSyncPlaybackRate: 2
};
