<script>
  import ThoughtSpotComponent from '@cox2m/city-services-ui-components/src/components/thoughtspot/ThoughtspotWrapper.svelte';

  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {menuOpts, selectedPark, user} from '../stores';

  let runtimeFilters = [{
    columnName: 'Camera Site',
    operator: 'IN',
    values: [$selectedPark.name]
  }]
</script>

<Modal>
  <MainScreen title="Analytics ThoughtSpot" menuOpt={$menuOpts} user={$user}>
    <div slot="main-dashboard" class="w-100 h-100">
      <ThoughtSpotComponent liveboardId='8bd96968-689a-4232-9473-6c233a1fcfb7' {runtimeFilters}/>
    </div>
  </MainScreen>

</Modal>
