<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import { menuOpts, user } from '../stores';
</script>

<style>
  .vh-85{
    height: 85vh !important;
  }
</style>



<MainScreen title="Logs" menuOpt={$menuOpts} user={$user}>
  <div slot='main-dashboard'>
    <iframe src="https://curbside-status-app.cox2m.com/?groups=smart-security" class="w-100 vh-85 border-0" title="description" name="ENV" />
  </div>
</MainScreen>
