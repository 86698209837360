import MaintenanceDashboard from './forms/MaintenanceDashboard.svelte';
import MonitoringRedirect from './forms/MonitoringRedirect.svelte';
import StatusMonitoring from './forms/StatusMonitoring.svelte';
import ParkingLotEntries from './forms/LicensePlates.svelte';
import GalleryScreen from './forms/GalleryScreen.svelte';
import AlertsWrapper from './forms/AlertsWrapper.svelte';
import ParkingSpaces from './forms/ParkingSpaces.svelte';
import DevicesScreen from "./forms/DevicesScreen.svelte";
import EventsScreen from './forms/EventsScreen.svelte';
import Dashboard from './forms/Dashboard.svelte';
import Analytics from './forms/Analytics.svelte';
import ParkHours from './forms/ParkHours.svelte';
import LiveView from './forms/LiveView.svelte';
import FreemontAnalytics from './forms/FreemontAnalytics.svelte';
import ThoughtSpotTest from "./forms/ThoughtSpotTest.svelte";

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/alerts': AlertsWrapper,
  '/park-hours': ParkHours,
  '/live-view/:id?': LiveView,
  '/park-assets': GalleryScreen,
  '/analytics/:page?': Analytics,
  '/violations/:id?': EventsScreen,
  '/thoughtspot-analytics': FreemontAnalytics,
  '/thoughtspot-test': ThoughtSpotTest,

  '/dashboard': Dashboard,
  '/maintenance-dashboard': MaintenanceDashboard,

  '/devices': DevicesScreen,

  '/monitoring': MonitoringRedirect,
  '/monitoring/status': StatusMonitoring,
  '/monitoring/parking-spaces': ParkingSpaces,
  '/monitoring/events/:id?/:date?': EventsScreen,
  '/monitoring/license-plates': ParkingLotEntries,

  //this page is the one redirecting to the view according to the user permissions
  '*': NotFoundPage
};

export const openRoutes = []

export default routes
