<script>
  import {push} from 'svelte-spa-router';
  import {user} from '../stores';

  const checkUrlRedirection = () => {
    if($user && $user.operations) {
      const goToEnforcementDashboard = $user.operations.includes('get-enforcement-dashboard');
      const goToMaintenanceDashboard = $user.operations.includes('get-parkrec-dashboard');
      console.log({user: $user, userOperations: $user.operations, goToEnforcementDashboard, goToMaintenanceDashboard})
      if(goToMaintenanceDashboard){
        push('/maintenance-dashboard');
      } else if(goToEnforcementDashboard){
        push('/dashboard');
      } else {
        push('/violations');
      }
    }
  }


  $: checkUrlRedirection($user)
</script>


<!-- we might need a 404 page in case we are getting errors with the user and we cannot fetch the permissions -->
