<script>
  import ResponsivePowerBiReportWrapper from '@cox2m/city-services-ui-components/src/components/ResponsivePowerBiReportWrapper.svelte';
  import DisplayOptionsPicker from '@cox2m/city-services-ui-components/src/components/DisplayOptionsPicker.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import CardContainer from './components/CardContainer.svelte';
  import CameraViewer from './components/CameraViewer.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {onMount} from 'svelte';
  import {getParksPeople, getAllSites} from '../actions';
  import {menuOpts, selectedPark, user} from '../stores';

  const token = getCookieByName(`${'ENV'}_token`);

  let modalContext;
  let loading = true;
  let parkPeopleInfo = undefined;

  let initialSelectedSite = null;
  let selectedSite = $selectedPark;
  let allSites = [];
  let fetchError = false;

  const dashboardDataFetch = async () => {
    loading = true;

    if (selectedSite && selectedSite.name) {
      const getParksPeopleResponse = await getParksPeople(
        token,
        selectedSite.name
      );

      if (getParksPeopleResponse.fulfilled) {
        parkPeopleInfo = getParksPeopleResponse.data;
      }else{
        fetchError = true;
      }
    }

    loading = false;
  };

  onMount(async () => {
    const getAllSitesResponse = await getAllSites(token);

    if (getAllSitesResponse.fulfilled) {
      selectedSite =
        $selectedPark.name ||
        Object.keys($selectedPark).length !== 0
          ? $selectedPark
          : getAllSitesResponse.sites[0];

      initialSelectedSite = selectedSite;

      selectedSite && selectedPark.setSelectedPark(selectedSite);

      allSites = getAllSitesResponse.sites;

      await dashboardDataFetch();
    }else{
      fetchError = true;
    }
    loading = false;
  });

  $: dashboardDataFetch(selectedSite);
</script>

<style>
  #report-container :global(.frame-container) {
    width: 100%;
    border-radius: 1rem;
    padding: 25%;
  }
  :global(#card-report-container iframe) {
    border: none;
  }
  #report-container :global(.float-right) {
    font-size: var(--cox2m-fs-700) !important;
  }
  .fs-800 {
    font-size: var(--cox2m-fs-800);
  }

  .maintenance-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--cox2m-spacing-4-units);
    margin-bottom: var(--cox2m-spacing-6-units);
  }

  .fs-1000 {
    font-size: var(--cox2m-fs-1000);
  }

  .loading-park-people-cards-container{
    height: 220px;
    display: grid;
    width: 100%;
    place-items: center;
    grid-column: 1/-1;
  }

  @media only screen and (min-width: 1200px) {
    .camera-viewer-container {
      padding-right: var(--cox2m-spacing-6-units);
      padding-left: var(--cox2m-spacing-2-units);
    }
  }

  @media only screen and (max-width: 1199px) {
    .camera-viewer-container {
      margin-bottom: var(--cox2m-spacing-12-units);
    }
    .maintenance-cards-container {
      grid-template-columns: 1fr 1fr;
    }
    :global(.maintenance-cards-container .card-item-container:first-of-type) {
      grid-column: -1/1;
    }
  }

  @media only screen and (max-width: 767px) {
    .maintenance-cards-container {
      grid-template-columns: 1fr;
    }
  }
</style>

<Modal bind:this={modalContext}>
  <MainScreen title="Parks & Rec Dashboard" menuOpt={$menuOpts} user={$user}>
    <div
      class="dashboard-component-container w-100 h-100"
      slot="main-dashboard">
      {#if loading}
        <div
          class="loading-container w-100 h-100 d-flex justify-content-center
          align-items-center">
          <LoadingSpinner />
        </div>
      {:else if fetchError}
      <div
          class="error-container h-100 d-flex justify-content-center
          align-items-center text-center">
          <h2 id="dashboard-page-error-message">
            We are sorry we couldn't fetch the page information, please reload
            the page or try again later
          </h2>
        </div>
      {:else if !loading && !fetchError}
        <span class="view-title">Overview</span>
        <DisplayOptionsPicker
          on:selected-option-change={e => {
            selectedSite = e.detail;
            selectedSite && selectedPark.setSelectedPark(e.detail);
          }}
          selectedOption={selectedSite || initialSelectedSite || allSites[0]}
          options={allSites}
          loading={false} />
        <div class="m-0 p-0">
          <span class="fs-1000 mt-4 mb-4 d-inline">
            Park Usage Last 24 Hours
          </span>
          <div
            class="maintenance-cards-container justify-content-left
            svelte-60imql">
            {#if parkPeopleInfo && $selectedPark && $selectedPark.name}
              <CardContainer
                title="Total"
                count={parkPeopleInfo.totalPeople}
                countText="People" />
              <CardContainer
                title="In Soccer Fields"
                count={parkPeopleInfo.peopleInSoccerFields}
                countText="People" />
              <CardContainer
                title="In Playgrounds"
                count={parkPeopleInfo.peopleInPlaygrounds}
                countText="People" />
            {:else}
              <div class="loading-park-people-cards-container">
                <LoadingSpinner />
              </div>
            {/if}
          </div>

          <div class="row pb-5 camera-viewer-container">
            <span class="fs-800 ml-1 d-xl-none font-weight-bold d-block mb-3">
              Camera Overview
            </span>
            <CameraViewer
              customClass="w-100"
              dashboardViewer
              {token}
              activateCameraCarousel />
          </div>

          <div class="row pb-5 m-0 mt-1 w-100" id="report-container">
            <div class="col-12 m-0 p-0 w-100">
              <ResponsivePowerBiReportWrapper
                reportParams={{desktopReport: {reportId: '12ebb6c3-c1dc-473a-85dd-ba235bc71ffa', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: '7a01ba85-a6f0-44dc-83b2-725d04b3cc2b', height: '600px'}, mobileReport: {reportId: '3696109c-f2e5-40b1-ba0d-c4f6c346e859', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: 'dc2cf488-bc87-44ef-bc8a-5ad107138294', height: '566px'}, modalReport: {reportId: '3696109c-f2e5-40b1-ba0d-c4f6c346e859', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: 'dc2cf488-bc87-44ef-bc8a-5ad107138294', height: '600px', showModal: true, title: 'Utilization by zone', pageName: 'ReportSection5b82a466db0e0606c067'}}} />
            </div>
          </div>
        </div>
      {/if}
    </div>
  </MainScreen>
</Modal>
