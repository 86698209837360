<script>
  import ResponsivePowerBiReportWrapper from '@cox2m/city-services-ui-components/src/components/ResponsivePowerBiReportWrapper.svelte';
  import DisplayOptionsPicker from '@cox2m/city-services-ui-components/src/components/DisplayOptionsPicker.svelte';
  import MapCluster from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapCluster.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Map from '@cox2m/city-services-ui-components/src/components/maps/arcgis/Map.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import CardContainer from './components/CardContainer.svelte';
  import EventCard from './components/EventCard.svelte';

  import {
    getMarkerConfig,
    getClusterConfig,
    buildPbiBasicFilter
  } from '../../funcs';
  import {formatDateString} from './components/scripts/events-screen/events-screen-funcs';
  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts, selectedPark, user} from '../stores';
  import {push} from 'svelte-spa-router';
  import {onMount} from 'svelte';
  import {
    getParksOvernightViolations,
    getViolationsUsingFilter,
    getSiteByName,
    getCameras,
    getAllSites,
  } from '../actions';

  const SEVEN_DAYS_MS = 1000 * 60 * 60 * 24 * 7;
  const EVENTS_START_DATE = new Date(new Date() - SEVEN_DAYS_MS);

  const token = getCookieByName(`${'ENV'}_token`);

  let modalContext;
  let eventsFetchingError = false;
  let violationEvents = [];
  let cameras = [];
  let site = null;
  let mapCenter = [];
  let loading = true;
  let mapView = undefined;
  let overnightViolationsInfo = undefined;

  let initialSelectedSite = null;
  let selectedSite = null;
  let allSites = [];

  const dashboardDataFetch = async () => {
    loading = true;

    const violationEventsResponse = await getViolationsUsingFilter(token, {
      limit: 5,
      siteName: selectedSite.name,
      since: formatDateString({
        isInitialDate: true,
        date: new Date(EVENTS_START_DATE).toISOString(),
        userPickedTime: null
      })
    });

    if (violationEventsResponse.fulfilled) {
      const [
        camerasResponse,
        siteResponse,
        overnightViolationsResponse
      ] = await Promise.all([
        getCameras(token, {
          siteName: selectedSite.name
        }),
        getSiteByName(token, selectedSite.name),
        getParksOvernightViolations(token, selectedSite.name)
      ]);

      if (
        violationEventsResponse.fulfilled &&
        camerasResponse.fulfilled &&
        siteResponse.fulfilled &&
        overnightViolationsResponse.fulfilled
      ) {
        overnightViolationsInfo = overnightViolationsResponse.data;
        violationEvents = violationEventsResponse.events;
        cameras = camerasResponse.cameras;
        site = siteResponse.site;
        mapCenter = site.coordinates.reverse();
      } else {
        eventsFetchingError = true;
      }
    } else {
      eventsFetchingError = true;
    }

    loading = false;
  };

  onMount(async () => {
    const getAllSitesResponse = await getAllSites(token);

    if (!getAllSitesResponse.fulfilled) {
      eventsFetchingError = true;
      loading = false;
    } else {
      selectedSite =
        $selectedPark.name || Object.keys($selectedPark).length !== 0
          ? $selectedPark
          : getAllSitesResponse.sites[0];

      initialSelectedSite = selectedSite;

      selectedSite && selectedPark.setSelectedPark(selectedSite);

      allSites = getAllSitesResponse.sites;

      await dashboardDataFetch();
    }
  });

  const handleMarkerClick = (data, event) => {
    if (mapView) {
      mapView.popup.autoOpenEnabled = false;
      mapView.popup.collapseEnabled = false;
      mapView.popup.open({
        title: `Camera ${data.name}`,
        location: event.mapPoint,
        content: `<b>${data.name}</b><br>${data.address}`
      });
      mapView.popup.visible = true;
    }
  };

  $: dashboardDataFetch(selectedSite);
</script>

<style>
  #events-counter-container {
    margin-top: var(--cox2m-spacing-10-units);
    margin-bottom: calc(
      var(--cox2m-spacing-1-units) + var(--cox2m-visual-corrector)
    );
  }

  #report-container :global(.frame-container) {
    width: 100%;
    border-radius: 1rem;
    padding: 25%;
    /* the padding is giving the height of the frame container for some reason is making it scale properly*/
  }
  #report-container :global(.float-right) {
    position: absolute !important;
  }
  #content-container {
    align-items: center;
  }

  #card-report-container :global(.frame-container) {
    width: 100%;
    border-radius: 1rem;
    padding: 25%;
  }

  #card-report-container :global(.frame-container) {
    padding: 6%;
  }

  :global(#card-report-container iframe) {
    border: none;
  }

  #card-report-container :global(.float-right) {
    display: none !important;
  }
  .fs-1000 {
    font-size: var(--cox2m-fs-1000);
  }
  .fs-800 {
    font-size: var(--cox2m-fs-800);
  }

  :global(#map-container canvas) {
    border-radius: 1rem;
  }

  #map-container {
    height: 384px;
  }

  .dashboard-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--cox2m-spacing-4-units);
    margin-bottom: var(--cox2m-spacing-6-units);
  }

  .view-title {
    color: var(--cox2m-clr-neutral-700);
    font-size: var(--cox2m-fs-1200);
  }

  @media only screen and (min-width: 1920px) {
    #content-container {
      align-items: flex-start;
    }
  }
  @media only screen and (min-width: 1200px) {
    .no-recent-events-box {
      min-height: 435px;
    }
    .camera-viewer-container {
      padding-right: var(--cox2m-spacing-6-units);
    }
  }

  @media only screen and (max-width: 1199px) {
    .dashboard-cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .camera-viewer-container {
      margin-bottom: var(--cox2m-spacing-12-units);
    }
    #content-container {
      flex-direction: column-reverse;
      padding-bottom: 0 !important;
    }
    /* first media class might be removed */
    #event-overview-cards-container {
      padding-left: var(--cox2m-spacing-4-units);
      padding-right: var(--cox2m-spacing-4-units);
      margin-top: var(--cox2m-spacing-2-units);
      margin-bottom: var(--cox2m-spacing-6-units);
    }
  }
</style>

<Modal bind:this={modalContext}>
  <MainScreen title="Public Safety Dashboard" menuOpt={$menuOpts} user={$user}>
    <div
      class="dashboard-component-container w-100 h-100"
      slot="main-dashboard">
      {#if loading}
        <div
          class="loading-container w-100 h-100 d-flex justify-content-center
          align-items-center">
          <LoadingSpinner />
        </div>
      {:else if eventsFetchingError}
        <div
          class="error-container h-100 d-flex justify-content-center
          align-items-center text-center">
          <h2 id="dashboard-page-error-message">
            We are sorry we couldn't fetch the page information, please reload
            the page or try again later
          </h2>
        </div>
      {:else}
        <span class="view-title">Overview</span>
        <DisplayOptionsPicker
          on:selected-option-change={e => {
            selectedSite = e.detail;
            selectedSite && selectedPark.setSelectedPark(e.detail);
          }}
          selectedOption={selectedSite || initialSelectedSite || allSites[0]}
          options={allSites}
          loading={false} />
        <div class="m-0 p-0">
          <span class="fs-1000 mt-4 mb-4 d-inline">
            Violations: Last 24 Hours
          </span>
          <div
            class="dashboard-cards-container justify-content-left svelte-60imql"
            id="card-report-container">
            <CardContainer
              title="After Hours"
              count={overnightViolationsInfo.peopleAfterHours}
              countText="People" />
            <CardContainer
              title="In Soccer Fields"
              count={overnightViolationsInfo.peopleInSoccerFields}
              countText="People" />
            <CardContainer
              title="In Playgrounds"
              count={overnightViolationsInfo.peopleInPlaygrounds}
              countText="People" />
            <CardContainer
              title="In Lot Overnight"
              count={overnightViolationsInfo.vehiclesInLotOvernight}
              countText="Vehicles" />
          </div>

          <div
            class="row pb-5"
            id="content-container"
            data-testid="content-container">
            <div class="col-xl-8 camera-viewer-container h-100">
              <span class="fs-800 ml-1 d-xl-none font-weight-bold d-block mb-3">
                Camera Overview
              </span>
              <div id="map-container" data-testid="map-container">
                <Map
                  props={{zoom: 16, center: mapCenter}}
                  basemap="hybrid"
                  bind:view={mapView}>
                  {#if !loading}
                    <MapCluster
                      layerTitle="Cameras"
                      collection={cameras}
                      onMarkerClick={handleMarkerClick}
                      markerConfig={getMarkerConfig()}
                      config={getClusterConfig()} />
                  {/if}
                </Map>
              </div>
            </div>
            <div
              class="col-xl-4 m-0 p-0 border-0"
              id="event-overview-container">
              <div>
                <div
                  id="event-overview-cards-container"
                  data-testid="event-overview-cards-container">
                  {#if violationEvents.length}
                    {#each violationEvents.slice(0, 3) as event, i}
                      <div
                        class={i !== 2 ? 'mb-2 pb-1' : ''}
                        id={`${event.id}-event-overview-card`}
                        on:click={() => push(`/violations/${event.id}?from=${event.createdAt.substring(0, 10) + 'T00:00:00.000'}`)}>
                        <EventCard {event} dashboardEvent isViolationEvent />
                      </div>
                    {/each}
                  {:else}
                    <div class="ml-1 no-recent-events-box">
                      <p class="fs-800 font-weight-bold">No recent events...</p>
                    </div>
                  {/if}
                </div>
              </div>
            </div>

          </div>

          <div class="row pb-5 m-0 mt-1 w-100" id="report-container">
            <div class="col-12 m-0 p-0 w-100">
              <ResponsivePowerBiReportWrapper
                filters={[buildPbiBasicFilter({
                    values: [$selectedPark.name || '']
                  })]}
                reportParams={{desktopReport: {reportId: 'e7496363-a5f1-44a1-8c29-792e6a39567c', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: 'd0dd1abe-2f1d-4df0-9075-20cd0ca866a3', height: '600px'}, mobileReport: {reportId: 'e7496363-a5f1-44a1-8c29-792e6a39567c', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: 'd0dd1abe-2f1d-4df0-9075-20cd0ca866a3', height: '400px'}, modalReport: {reportId: 'e7496363-a5f1-44a1-8c29-792e6a39567c', workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747', datasetIds: 'd0dd1abe-2f1d-4df0-9075-20cd0ca866a3', height: '600px', showModal: true}}} />
            </div>
          </div>
        </div>
      {/if}
    </div>
  </MainScreen>
</Modal>
